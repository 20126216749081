import { cohortsData } from "data/apply/cohorts";
import { Partners } from "enum/partners";

import HiddenInput from "components/atoms/HiddenInput";
import SubmitButton from "components/atoms/SubmitButton";
import EmailInput from "components/molecules/EmailInput";
import FirstNameInput from "components/molecules/FirstNameInput";
import FormContainer from "components/molecules/FormContainer";
import LastNameInput from "components/molecules/LastNameInput";
import PhoneInput from "components/molecules/PhoneInput";
import PolicyAgreement from "components/molecules/PolicyAgreement/index";
import USCitizenInput from "components/molecules/USCitizenInput";

import { ChannelsForTracking } from "lib/tracker";

import { ApplicationFlows } from "types/apply.types";

interface Props {
  leadContext: string;
  nextStepRedirectPath: string;
  continueApplicationLink?: string;
  children?: React.ReactNode;
  flow?: ApplicationFlows;
  partner?: Partners;
  channelsForTrackingMerge?: ChannelsForTracking;
  cta?: string;
}

export default function ApplyStepOneForm(props: Props) {
  const nextCohort = cohortsData.find(
    (cohort) => cohort.program === "sdr" && cohort.schedule === "pt"
  );
  return (
    <FormContainer
      leadContext={props.leadContext}
      step={1}
      stepName="Lead Form"
      isApplicationComplete={false}
      nextStepRedirectPath={props.nextStepRedirectPath}
      continueApplicationLink={props.continueApplicationLink}
      flow={props.flow}
    >
      <div className="space-y-6">
        <FirstNameInput />
        <LastNameInput />
        <EmailInput />
        <PhoneInput />
        <USCitizenInput />
        {props.partner && <HiddenInput name="partner" value={props.partner} />}
        <HiddenInput
          name="cohortNumber"
          value={nextCohort.cohortNumber}
          registerOptions={{ valueAsNumber: true }}
        />
        {props.children}
      </div>
      <div className="mt-11">
        <SubmitButton>{props.cta || "Start my application"}</SubmitButton>
      </div>
      <PolicyAgreement />
    </FormContainer>
  );
}
