import reduce from "lodash/reduce";

import {
  CohortDate,
  CohortSchedule,
  DefinedProgramType,
} from "types/common.types";

type ScheduleData = Record<
  CohortSchedule,
  { date: string; cohortNumber: string }
>;
type CohortBase = Record<DefinedProgramType, ScheduleData>;

const cohorts: CohortBase = {
  sdr: {
    ft: null,
    pt: {
      date: "Aug 28",
      cohortNumber: "102",
    },
    ptAmazon: {
      date: "Aug 28",
      cohortNumber: "102",
    },
  },
  cs: {
    ft: {
      date: "Aug 28",
      cohortNumber: "00",
    },
    pt: {
      date: "Aug 28",
      cohortNumber: "00",
    },
    ptAmazon: null,
  },
};

const cohortsData = reduce(
  cohorts,
  (acc, { pt, ft }, key: DefinedProgramType) => {
    acc.push(
      ...[
        pt && {
          program: key,
          schedule: "pt" as CohortSchedule,
          ...pt,
        },
        ft && {
          program: key,
          schedule: "ft" as CohortSchedule,
          ...ft,
        },
      ].filter(Boolean)
    );
    return acc;
  },
  [] as CohortDate[]
);

const amazonCohortsData = cohorts.sdr.ptAmazon;

export { amazonCohortsData, cohortsData };
